import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./Register.module.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { userSchema } from "../../schema/user.schema";
import { registerService } from "../../services/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { confirmPassword, ...requestData } = data;
    console.log(requestData);
    requestData.role = "ROLE_USER";

    try {
      const response = await registerService(requestData);
      toast.success("Registered successfully!");
      navigate("/login");
      reset();
    } catch (error) {
      toast.error(error.message || "Registration failed. Please try again.");
    }
  };

  return (
    <div className={styles.signupPage}>
      <div className={styles.signup}>
        <p className={styles.headingL}>Register</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputGroup}>
            <Input
              placeholder="First Name"
              name="firstName"
              type="firstName"
              register={register}
              error={errors.firstName}
            />
            <Input
              placeholder="Last Name"
              name="lastName"
              type="lastName"
              register={register}
              error={errors.lastName}
            />
          </div>
          <Input
            placeholder="Email address"
            name="email"
            type="email"
            register={register}
            error={errors.email}
          />

          <Input
            placeholder="Company"
            name="company"
            type="company"
            register={register}
            error={errors.company}
          />
          <div className={styles.inputGroup}>
            <Input
              placeholder="Role"
              name="role"
              type="role"
              register={register}
              error={errors.role}
            />
            <Input
              placeholder="Title"
              name="title"
              type="title"
              register={register}
              error={errors.title}
            />
          </div>

          <div className={styles.inputGroup}>
            <Input
              placeholder="Password"
              name="password"
              type="password"
              register={register}
              error={errors.password}
            />
            <Input
              placeholder="Confirm password"
              name="confirmPassword"
              type="password"
              register={register}
              error={errors.confirmPassword}
            />
          </div>
          <Button text="Create an account" type="submit" />
        </form>
        <p className={styles.bodyM}>
          Already have an account?
          <span>
            <Link to="/login"> Login</Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
