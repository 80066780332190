import { httpClient } from "../httpClient";

export async function loginService(params) {
  try {
    const response = await httpClient.post("v1/authentication/login", params);

    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.response.data.message);
    }

    throw new Error("An error occurred");
  }
}

export async function registerService(params) {
  try {
    const response = await httpClient.post(
      "v1/authentication/register",
      params
    );

    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message);
    }

    throw new Error("An error occurred");
  }
}

export async function getUserById(userId) {
  try {
    const response = await httpClient.get(`v1/user/info/${userId}`);
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message);
    }
    throw new Error("An error occurred ");
  }
}
