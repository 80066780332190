import React from "react";
import styles from "./Login.module.css";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../../schema/user.schema.js";
import { getUserById, loginService } from "../../services/auth.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../context/UserContext.js";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const { setUser } = useUser();

  const onSubmit = async (data) => {
    try {
      const response = await loginService(data);
      const token = response.accessToken;
      const decodedToken = jwtDecode(token);

      const userId = decodedToken.id;

      const userData = await getUserById(userId);
      console.log(userData);
      setUser(userData);

      toast.success("Login successful!");
      navigate("/profile");
      reset();
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Login failed. Please try again.");
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.login}>
        <p className={styles.headingL}>Login</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Email address"
            name="email"
            type="email"
            register={register}
            error={errors.email}
          />
          <Input
            placeholder="Password"
            name="password"
            type="password"
            register={register}
            error={errors.password}
          />
          <Button text="Login to your account" type="submit" />
        </form>
        <p className={styles.bodyM}>
          Don’t have an account?
          <span>
            <Link to="/register"> Register</Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
