import React from "react";
import styles from "./Profile.module.css";
import Detail from "../../components/Detail/Detail";
import { useUser } from "../../context/UserContext";

const Profile = () => {
  const { user } = useUser();
  console.log(user);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.profilePage}>
      <div className={styles.profileSection}>
        <div className={styles.header}>
          <div className={styles.headingL}>Account details</div>
          <div className={styles.headingXs}>
            You can find account information here
          </div>
        </div>
        <div className={styles.seperator}></div>
        <div className={styles.profile}>
          <div className={styles.profileImage}></div>
          <div className={styles.fullName}>
            {user.first_name} {user.last_name}
          </div>
        </div>
        <div className={styles.details}>
          <Detail title="Email" info={user.email} />
          <Detail title="Company" info={user.company} />
          <Detail title="Title" info={user.title} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
